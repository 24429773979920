/* istanbul ignore file */
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
}

* {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@font-face {
  font-family: "MTNBrighterSans-Bold";
  src: local("MTN"),
    url(../assets/fonts/MTNBrighterSans-Bold.otf) format("opentype");
  font-display: swap;
}
.mtn-bold {
  font-family: "MTNBrighterSans-Bold";
}
@font-face {
  font-family: "MTNBrighterSans-Light";
  src: local("MTN"),
    url(../assets/fonts/MTNBrighterSans-Light.otf) format("opentype");
  font-display: swap;
}
.mtn-light {
  font-family: "MTNBrighterSans-Light";
}

@font-face {
  font-family: "MTNBrighterSans-Medium";
  src: local("MTN"),
    url(../assets/fonts/MTNBrighterSans-Medium.otf) format("opentype");
  font-display: swap;
}
.mtn-medium {
  font-family: "MTNBrighterSans-Medium";
}
@font-face {
  font-family: "MTNBrighterSans-Regular";
  src: url(../assets/fonts/MTNBrighterSans-Regular.otf) format("opentype");
  font-display: swap;
}
.mtn-regular {
  font-family: "MTNBrighterSans-Regular";
}

@font-face {
  font-family: "MTNBrighterSans-RegularItalic";
  src: url(../assets/fonts/MTNBrighterSans-RegularItalic.otf) format("opentype");
  font-display: swap;
}
.mtn-regular-italic {
  font-family: "MTNBrighterSans-RegularItalic";
}

.toc-content ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.toc-content ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.toc-content ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

.toc-content li ol > li {
  margin: 0 0 8px 0;
}

.toc-content li ol > li:before {
  content: counters(item, ".") " ";
}

.toc-content li ol.roman-numerals > li:before {
  list-style-type: none !important;
  counter-increment: none !important;
}

body {
  font-family: "MTNBrighterSans-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -ms-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
  opacity: 0.4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  top: 20px;
  background: "none";
  border: 0;
  font-size: 32px;
  cursor: pointer;
}
.carousel .control-arrow:focus,
.carousel .control-arrow:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: "";
}
.carousel .control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: inherit;
  display: none;
}
.carousel .control-prev.control-arrow {
  left: 0;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #333333;
}
.carousel .control-next.control-arrow {
  right: 0;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #333333;
}
.carousel-root {
  outline: none;
}
.carousel {
  position: relative;
  width: 100%;
}
.carousel * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none;
}
.carousel .carousel {
  position: relative;
}
.carousel .control-arrow {
  outline: 0;
  border: 0;
  background: none;
  top: 50%;
  margin-top: 140px;
  font-size: 18px;
}
.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden;
}
.carousel .thumbs {
  -webkit-transition: all 0.15s ease-in;
  -moz-transition: all 0.15s ease-in;
  -ms-transition: all 0.15s ease-in;
  -o-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap;
}
.carousel .thumb {
  -webkit-transition: border 0.15s ease-in;
  -moz-transition: border 0.15s ease-in;
  -ms-transition: border 0.15s ease-in;
  -o-transition: border 0.15s ease-in;
  transition: border 0.15s ease-in;
  display: inline-block;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 2px;
}
.carousel .thumb:focus {
  border: 3px solid #ccc;
  outline: none;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid #333;
}
.carousel .thumb img {
  vertical-align: top;
}
.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
}
.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  /* bottom: 0; */
  /* margin-top: 0; */
  padding: 5px;
}
.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0.2);
}
.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  -webkit-transition: height 0.15s ease-in;
  -moz-transition: height 0.15s ease-in;
  -ms-transition: height 0.15s ease-in;
  -o-transition: height 0.15s ease-in;
  transition: height 0.15s ease-in;
}
.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column;
}
.carousel .slider-wrapper.axis-vertical {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
.carousel .slider-wrapper.axis-vertical .slider {
  -webkit-flex-direction: column;
  flex-direction: column;
}
.carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  width: 100%;
}
.carousel .slider.animated {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
}
.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
}
.carousel .slide iframe {
  display: inline-block;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  border: 0;
}
.carousel .slide .legend {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  opacity: 0.25;
  -webkit-transition: opacity 0.35s ease-in-out;
  -moz-transition: opacity 0.35s ease-in-out;
  -ms-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}

/* promo  */
.promo .carousel .control-dots {
  position: absolute;
  bottom: 30px;
  margin: 10px 0;
  padding: 0;
  text-align: start;
  width: 100%;
  z-index: 1;
  left: 17px;
}
.promo .carousel .control-dots .dot {
  -webkit-transition: opacity 0.25s ease-in;
  -moz-transition: opacity 0.25s ease-in;
  -ms-transition: opacity 0.25s ease-in;
  -o-transition: opacity 0.25s ease-in;
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: #555555;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}

.intro .carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
}
@media (min-width: 960px) {
  .carousel .control-dots {
    bottom: 0;
  }
}
.intro .carousel .control-dots .dot {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: rgba(51, 51, 51, 0.6);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
.intro .carousel .control-dots .dot.selected,
.intro .carousel .control-dots .dot:hover {
  background: #000;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}

.promo .carousel .control-dots .dot.selected,
.promo .carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #fff;
}
.carousel:hover .slide .legend {
  opacity: 1;
}


ol {
  counter-reset: li; 
  margin-left: 0; 
  padding-left: 0;
  font-size: 14px;
}
ol > li {
  position: relative; 
  margin: 0 0 18px 2em; 
  padding: 4px 8px; 
  list-style: none; 
}
ol > li:before {
  content: counter(li); 
  counter-increment: li; 
  position: absolute;
  top: -2px;
  left: -2em;
  border-radius: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 2em;
  margin-right: 8px;
  padding: 4px;
  color: #333333;
  background: #FFCB05;
  text-align: center;
}
li ol,
li ul {
  margin-top: 6px;
}
ol ol li:last-child {
  margin-bottom: 0;
}
